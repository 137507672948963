import React from 'react'
import './CaptionOne.css'

function CaptionTwo() {
    return (
        <div className='caption_one_frame'>
            <h2 className='caption_one_title'>{"Encryption\nConfidence"}</h2>
            <h2  className='caption_one_medtit'>Podax has implemented best-in-class, open-source encryption - all for free for you to use.</h2>
            <h4 className='caption_one_desc'>{"The application currently uses AES 256 CTR encryption, an industry standard encryption that's used by government agencies to transmit Top-Secret/Classified information...\n\n Don't believe us? You can view our source code by clicking the GitHub icon in the top right.n "}</h4>
        </div>
    )
}

export default CaptionTwo
