import React from 'react'
import './DownloadOptions.css'

function DownloadOptions() {
    return (


                 <div className="dl_opt_frame">


                    <div className="dl_opt_disabled">
                        <p className="dl_text">Download Windows</p>
                    </div>

                    <div className="dl_opt_disabled">
                        <p className="dl_text">Download MacOS</p>
                    </div>

                    <div className="dl_opt_disabled">
                        <p className="dl_text">Download Linux</p>
                    </div>

        </div>
    )
}

export default DownloadOptions
