import React from 'react'
import gitlogo from './github.png'
import './AppLinks.css'



function AppLinks() {


    return (
        <div className="app_links_holder">
            <div className='subholder'>
            <a href="https://github.com/axrati/podax"><h5 className="app_links_text">GitHub</h5></a>
            <a href="https://github.com/axrati/podax"><img src={gitlogo} className="gitlogo"  /></a>
            </div>
            
        </div>
    )
}





export default AppLinks
