import React from 'react'
import phantom from './phantom.png'
import os from './os.png'
import dsc from './disconnected.png'
import './Sellpoints.css'

function Sellpoints() {
    return (
        <div className="sellframe">

            <div className="sellpoint">
                <img src={os} className="sellpoint_img"/>
                <h1 className="sellpoint_title">Open Source</h1>
                <h2 className="sellpoint_desc">Podax source code is available for you to view, we have nothing to hide</h2>
            </div>

            <div className="sellpoint">
                <img src={dsc} className="sellpoint_img"/>
                <h1 className="sellpoint_title">Network Free</h1>
                <h2 className="sellpoint_desc">Purely offline application, no inbound/outbound network calls made</h2>
            </div>

            <div className="sellpoint">
                <img src={phantom} className="sellpoint_img"/>
                <h1 className="sellpoint_title">Phantom Keys</h1>
                <h2 className="sellpoint_desc">Keys are never stored and are unrecoverable after encryption</h2>
            </div>
            
        </div>
    )
}

export default Sellpoints
