import logo from './podax_logo.png';
import './App.css';
import AppLinks from './components/AppLinks';
import Sellpoints from './components/Sellpoints';
import DownloadOptions from './components/DownloadOptions';
import CaptionOne from './components/CaptionOne';
import CaptionTwo from './components/CaptionTwo';
import CaptionThree from './components/CaptionThree';

function App() {
  return (
    <div className="App">
              <AppLinks/>
        <div className='space_header'></div>
        <img src={logo} className="podax_logo"  />

        <h1 className="podax_exp">Open Source Encryption Software</h1>
        <h3 className="podax_quote"><span className="quote_italic">{"\"It's a simple spell, but quite unbreakable\""}</span>{"\n- Doctor Strange"}</h3>
        <div className="breaker"></div>
        <Sellpoints/>
        <div className="breaker"></div>
        <CaptionOne />
        <div className="breaker"></div>
        <CaptionTwo />
        <div className="breaker"></div>
        <CaptionThree />

        <div className="breaker"></div>
        <DownloadOptions/>
        <div className="breaker"></div>
    </div>
  );
}

export default App;
