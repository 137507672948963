import React from 'react'
import './CaptionOne.css'
function CaptionOne() {
    return (
        <div className='caption_one_frame'>
            <h2 className='caption_one_title'>{"Data Privacy\nBuilt For You"}</h2>
            <h4 className='caption_one_medtit'>You shouldn't have to be a computer expert to protect your information in the cyber space.</h4>
            <h4 className='caption_one_desc'>{"Podax was built with individuals in mind. Everybody has a right to privacy, and its our mission to enable everybody to exercise it.\n\nWhether its information you want shielded from hackers, the NSA or anyone else - use Podax to encrypt your files in a way that only you can decypher."}
            </h4>
            
            
        </div>
    )
}

export default CaptionOne

