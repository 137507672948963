import React from 'react'
import './CaptionOne.css'

function CaptionThree() {
    return (
        <div className='caption_one_frame'>
            <h2 className='caption_one_title'>{"Private\nKey Handling"}</h2>
            <h4  className='caption_one_medtit'>Podax doesn't save passwords, so you can be confident that you're encrypting data without a trace.</h4>
            <h4 className='caption_one_desc'>{"This application will also recommend strong encryption keys for you to use, though you are free to use any key you want. \n\nThe key generation method we use is similar to cryptocurrency wallets - our vocabulary list is the same one that Monero uses."}</h4>
        </div>
    )
}

export default CaptionThree
